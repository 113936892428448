<template
  ><div>
    <h1>Switch Upload <b-badge class="updates-badge" variant="primary" pill @click="showUpdates">Updates</b-badge></h1>
    <b-card class="mb-30" v-if="!uploading">
      <b-form-group label="Select hotel">
        <hotel-selector v-model="selectedHotel" id="hotel-selector" />
      </b-form-group>

      <b-form-group label="VLANs">
        <b-file name="vlans" @change="handleVlanFileChange"></b-file>
      </b-form-group>
      <b-form-group label="Switches">
        <b-file name="switches" @change="handleSwitchFileChange"></b-file>
      </b-form-group>
      <b-form-group class="mt-4">
        <b-button variant="primary" @click.prevent="submit" :disabled="invalidHotel">
          Go
        </b-button>
      </b-form-group>
    </b-card>
    <b-card v-else>
      <div class="text-center">
        <div v-if="errorMessage === ''">
          <div v-if="!completed">
            <div class="spinner-bubble spinner-bubble-warning m-5"></div>
            <h2>Uploading</h2>
          </div>
          <div v-else>
            <i class="i-Yes"></i>
            <h2>Complete</h2>
            <b-button variant="primary" @click.prevent="reset">Close</b-button>
          </div>
        </div>
        <div v-else>
          <h2>Error</h2>
          <b-alert :show="true" variant="danger">{{ errorMessage }}</b-alert>
          <b-button variant="primary" @click.prevent="tryAgain">Try Again</b-button>
        </div>
      </div>
    </b-card>
    <b-modal id="updatesModal" centered ok-only title="" size="lg">
      <div>
        <h3>2021-06-04</h3>
        <ul>
          <li>
            Import no longer requires VLANs to be deleted in NetBox, with the following considerations:
            <ul>
              <li>Existing VLAN IDs are ignored in the uploaded data, no updates are parsed</li>
              <li>New VLANs in the uploaded data are parsed and imported to NetBox as usual</li>
              <li>
                The uploaded VLAN data must contain, at a minimum, the MGMT_Switch VLAN with its IP so the importer can
                determine the subnet needed
              </li>
            </ul>
          </li>
          <li>
            Consequently, additional switches can be imported after the first upload with the following considerations:
            <ul>
              <li>No updates are performed. Trying to re-import an existing switch will result in an error</li>
              <li>
                VLAN data must be uploaded, which may include new VLANs for the new switch, but must include at least
                the MGMT_Switch VLAN
              </li>
            </ul>
          </li>
          <li>
            Serial Number and MAC Address are now parsed and added to the Device and management interface respectively.
          </li>
        </ul>
      </div>
    </b-modal>
  </div>
</template>

<script>
import HotelSelector from "@/components/HotelSelector";
import { computed, ref } from "@vue/composition-api";
import ApiClient from "@/ApiClient";

export default {
  components: {
    HotelSelector
  },
  name: "SwitchUpload",
  setup(props, { root }) {
    const selectedHotel = ref({ id: null, name: "", dnsName: "" });
    const vlanString = ref("");
    const switchesString = ref("");
    const uploading = ref(false);
    const completed = ref(false);
    const errorMessage = ref("");
    const vlanFile = ref("");
    const switchesFile = ref("");

    const invalidHotel = computed(() => {
      return selectedHotel.value === null || selectedHotel.value.dnsName === "";
    });

    function submit() {
      uploading.value = true;
      completed.value = false;
      const formData = new FormData();
      formData.append("site", selectedHotel.value.dnsName);
      formData.append("vlans", vlanFile.value);
      // formData.append("vlans", vlanString.value);
      formData.append("switches", switchesFile.value);

      ApiClient.post("/ss/post", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(response => {
          console.log(response);
          completed.value = true;
        })
        .catch(error => {
          if (error.response) {
            errorMessage.value = error.response.data.detail;
          }
        });
    }

    function handleVlanFileChange($event) {
      vlanFile.value = $event.target.files[0];
    }
    function handleSwitchFileChange($event) {
      switchesFile.value = $event.target.files[0];
    }

    function tryAgain() {
      errorMessage.value = "";
      uploading.value = false;
      completed.value = false;
    }
    function reset() {
      errorMessage.value = "";
      uploading.value = false;
      vlanString.value = "";
      switchesString.value = "";
      completed.value = false;
    }

    function showUpdates() {
      root.$bvModal.show("updatesModal");
    }

    return {
      selectedHotel,
      invalidHotel,
      vlanString,
      switchesString,
      submit,
      uploading,
      errorMessage,
      completed,
      tryAgain,
      reset,
      handleVlanFileChange,
      handleSwitchFileChange,
      showUpdates
    };
  }
};
</script>

<style>
.updates-badge {
  font-size: 0.5em;
  vertical-align: middle;
  cursor: pointer;
}
</style>
